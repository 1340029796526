@import 'colors.scss';

footer {
  height: unset;
}

.footer-link {
  font-size: 3em !important;
  color: $white;
  &:hover {
    color: $light_green;
  }
}

#footer-disclaimer {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  margin: 0 !important;
}


@media screen and (orientation: portrait) and (max-width: 820px) {
  .footer-link {
    font-size: 2.5em !important;
  }
  #footer-disclaimer {
    font-size: 0.8em;
  }
}

@media screen and (orientation: portrait) and (max-width: 420px) {
  #footer-links {
    padding-bottom: 0 !important;
  }
  .footer-link {
    font-size: 2.3em !important;
  }
  #footer-disclaimer {
    font-size: 0.7em;
  }
}


@media screen and (orientation: portrait) and (max-height: 920px) {
  footer {
    height: 50px;
  }
}

@media screen and (orientation: portrait) and (max-height: 640px) {
  footer {
    height: 30px;
  }
}
