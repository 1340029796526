@import 'colors.scss';

.hero {
  font-family: 'Exo', sans-serif;
}

h1 {
  font-size: 6rem !important;
  color: $dark_green;
  text-shadow: 3px 3px $shadow;
}

@media only screen and (max-width: 430px) {
  h1 {
    font-size: 4rem !important;
  }
}


