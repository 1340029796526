@import 'colors.scss';


#homepage {
  flex: 1;
  overflow: auto;
}

#landing-card-subtitle {
  margin-top: 5px !important;
}

#landing-card {
  height: 100%;
  font-family: 'Exo', sans-serif;
  p {
    color: $white;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 1px 1px $shadow;
  }
  h1 {
    border-bottom: 0;
    padding: 0;
    font-size: 5rem !important;
    color: $white;
    text-shadow: 3px 3px $shadow;
  }
  h3 {
    display: flex;
    border-bottom: 0;
    font-size: 3rem !important;
    font-weight: bold;
    padding: 0;
    color: $light_green;
    text-shadow: 2px 2px $shadow;
  }
}

#landing-card-overlay {
  display: block;
  overflow: hidden;
  padding: 155px 105px;
}

picture {
  height: 100%;
}

#landing-img {
  object-fit: fill;
  height: 100%;
  opacity: 0.5;
  filter: grayscale(50%);
}

#landing-avatar {
  position: absolute;
  object-fit: cover;
  bottom: 50px;
  right: 50px;
  height: 450px;
  width: auto;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 30px 30px #000000;
}

.landing-btn {
  margin: 50px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder !important;
  font-style: italic;
}


@media screen and (orientation: portrait) and (min-width: 1080px) and (min-height: 2100px) {

  #landing-card-subtitle {
    margin-top: 8px !important;
  }

  #landing-card {
    p {
      font-size: 24px;
    }
    h1 {
      font-size: 6rem !important;
    }
    h3 {
      font-size: 3.5rem !important;
    }
  }

  #landing-avatar {
    height: 60vw;
    box-shadow: 0 0 20px 20px #000000;
  }

  #landing-card-overlay {
    padding: 180px 115px;
  }

  .landing-btn {
    font-size: 1.7rem !important;
  }
}

@media screen and (orientation: portrait) and (max-width: 820px) {

  #landing-avatar {
    height: 45vw;
    bottom: 30px;
    right: 30px;
  }

  #landing-card-overlay {
    padding: 25px;
  }
}

@media screen and (orientation: portrait) and (max-width: 460px) {

  #landing-card {
    h1 {
      font-size: 2.3rem !important;
    }
    h3 {
      font-size: 1.8rem !important;
    }
    p {
      font-size: 10px;
    }
  }

  #landing-avatar {
    height: 50vw;
    box-shadow: 0 0 7px 7px #000000;
    bottom: 20px;
    right: 20px;
  }

  .landing-btn {
    margin: 15px 15px 0 0;
    font-size: 0.75rem !important;
    padding: 0.25rem 0.75rem !important;
  }
}

@media screen and (orientation: portrait) and (max-width: 360px) {

  #landing-card {
    h1 {
      font-size: 2.4rem !important;
      text-shadow: 2px 2px $shadow;
    }
    h3 {
      font-size: 1.5rem !important;
      text-shadow: 1px 1px $shadow;
    }
    p {
      font-size: 11px;
    }
  }

  #landing-avatar {
    height: 42vw;
    box-shadow: 0 0 5px 5px #000000;
    bottom: 10px;
    right: 10px;
  }

  .landing-btn {
    margin: 15px 10px 0;
  }
}

@media screen and (orientation: portrait) and (max-width: 320px) {

  #landing-card {
    h1 {
      font-size: 1.8rem !important;
    }
    h3 {
      font-size: 1.2rem !important;
    }
    p {
      font-size: 10px;
    }
  }

  .landing-btn {
    margin: 15px 10px 0 5px;
    font-size: 0.6rem !important;
  }
}


@media only screen and (orientation: landscape) and (max-width: 1024px) {


  #landing-card-overlay {
    padding: 25px;
  }

  #landing-avatar {
    height: 25vw;
    bottom: 25px;
    right: 25px;
    box-shadow: 0 0 20px 20px #000000;
  }

  .landing-btn {
    margin: 30px;
  }
}

@media only screen and (orientation: landscape) and (max-width: 840px) {

  html {
    height: unset;
  }

  #landing-avatar {
    height: 25vw;
    bottom: 25px;
    right: 25px;
  }

  .landing-btn {
    margin: 20px;
    font-size: 1rem !important;
  }
}

@media only screen and (orientation: landscape) and (max-width: 640px) {

  #landing-avatar {
    bottom: 15px;
    right: 15px;
    box-shadow: 0 0 10px 10px #000000;
  }

  #landing-card {
    h1 {
        font-size: 3.8rem !important;
      }
    h3 {
      font-size: 2.5rem !important;
    }
  }
}
