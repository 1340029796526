@import 'colors.scss';

#projectpage {
  flex: 1;
  overflow: auto;
}


.project-category-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  font-style: italic;
  font-size: 1.25rem !important;
  color: $light_green;
  text-shadow: 2px 2px $shadow;
}

#mobile_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fallback-mobile-project-card, .fallback-project-card {
  background-color: $background_dark;
  border: 1px solid white;
  border-radius: 1em !important;
  text-align: center;
  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 15px 0;
  }
}

.fallback-mobile-project-card {
  width: 32%;
  margin-bottom: 25px;
}

.fallback-project-card {
  margin-bottom: 20px;
}


.loading-spinner {
  color: white;
  margin-bottom: 25px;
}

@media screen and (orientation: portrait) and (max-width: 620px) {
  .fallback-mobile-project-card {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 430px) {
  .project-category-header {
    font-size: 1rem !important;
  }
}
