@import 'colors.scss';

textarea#message {
  resize: none;
}

.form-control:focus {
  border-color: $dark_green !important;
  box-shadow: 0 0 5px $shadow !important;
}

.form-label {
  color: #F0EDEE;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  font-style: italic;
}

.invalid-feedback {
  background-color: rgba(248,215,218, 0.7);
  padding: 0 5px;
  width: max-content !important;
  border-radius: 0.25rem;
  border: solid 1px #f6cbd0;
}

#form-btn {
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder !important;
  font-style: italic;
}
