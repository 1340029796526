@import 'colors.scss';
@import 'fonts.scss';

.appbody {
  display: flex;
  flex-flow: column;
}

body {
  background: linear-gradient(to bottom, $background_dark, $background_light) fixed;
}

p {
  color: $white;
}


html, body, #root, .appbody {
  height: 100%;
}

.app {
  height: 92%;
}


@media screen and (orientation: portrait) and (max-width: 820px) {
  .app {
    height: 90%;
  }
}

@media screen and (orientation: portrait) and (max-width: 460px) {
  .app {
    height: 87%;
  }
}
