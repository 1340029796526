@import 'colors.scss';

#navbar {
  background-color: #26282C;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold;
  font-style: italic;
  .nav-link {
    cursor: pointer;
    color: $white;
    &:hover {
      color: $light_green;
    }
  }
  .nav-link.active {
    color: $light_green;
  }
}

#navbar-hamburger {
  color: $white;
  font-size: 25px;
}
