@import 'colors.scss';

.avatar {
  border: solid 3px black;
  border-radius: 50%;
  background-color: #F0EDEE;
  width: 250px;
}

#aboutpage {
  flex: 1;
  overflow: auto;
}

#aboutpage-body {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
}
